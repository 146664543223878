import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import usePaginationSearchForStock from '../../../../hooks/usePaginationSearchForStock';
import Loader from '../../../Shared/Loader';

const CurrentStock = () => {
    const [sales, setSales] = useState([]);
    const [prod, setProd] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [sort, setSort] = useState('All');
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [returnSales, setReturnSales] = useState([]);
    const { items, pageCount, limit, pageNo, loading, handleSearchResult,
        handleLimit, handlePageClick, refetchData, selectedCategory, handleCategoryChange } = usePaginationSearchForStock('https://servers.expressmartbd.com/jibon-traders/getproductswithfilter')

    const sortRef = useRef('');
    const handleSort = () => {
        const sortValue = sortRef.current.value;
        setSort(sortValue);
    }

    console.log(items);


    useEffect(() => {
        const url = `https://servers.expressmartbd.com/jibon-traders/getcategories`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setCategories(data);
            })
    }, []);

    useEffect(() => {
        fetch(`https://servers.expressmartbd.com/jibon-traders/getcurrentstock`)
            .then(res => res.json())
            .then(data => {
                const filtered = data.stocks.filter((product) => {
                    return product.stock > 0;
                });
                setProd(filtered);
                // console.log(data.stocks);
                // console.log(filtered);
                // setLoading(false)
            })
    }, [])

    useEffect(() => {
        const url = `https://servers.expressmartbd.com/jibon-traders/getsalesproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setSales(data);
            })
    }, []);

    useEffect(() => {
        const url = `https://servers.expressmartbd.com/jibon-traders/getpurchaseproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setPurchases(data);
            })
    }, []);

    useEffect(() => {
        const url = `https://servers.expressmartbd.com/jibon-traders/getreturnsalesproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setReturnSales(data);
            })
    }, []);

    // const { data: items, isLoading } = useQuery(['items'], () => fetch('https://servers.expressmartbd.com/jibon-traders/getproducts').then(res => res.json()));

    //     const { data: {items}, isLoading } = useQuery(
    //         ['items', searchTerm], // Include the searchTerm in the query key
    //         () => {
    //             // Fetch items only if there's a search term; otherwise, return an empty array
    //             // if (searchTerm) {
    //             return fetch(`https://servers.expressmartbd.com/jibon-traders/getproductsforcurrentstock?page=1&limit=20&search=${encodeURIComponent(searchTerm)}`).then(
    //                 (res) => res.json()
    //             );
    //             // } else {
    //             //     return Promise.resolve([]); // Return an empty array when there's no search term
    //             // }
    //         }
    //     );

    // console.log(items);
    // Stock Report

    // Sales Quantity

    let array = [];

    for (let i = 0; i < items?.length; i++) {
        const filtered = sales.filter(sale => sale?.code === items[i]?.code);
        array.push(filtered);
    }

    let array2 = [];
    for (let i = 0; i < array?.length; i++) {
        const salesQuantity = array[i]?.map(a => parseFloat(a.quantity));
        const totalSales = salesQuantity?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        array2.push(totalSales);
    }

    // Purchase Quantity

    let array3 = [];
    for (let i = 0; i < items?.length; i++) {
        const filtered = purchases.filter(purchase => purchase.code === items[i].code);
        array3.push(filtered);
    }

    let array4 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array3[i]?.map(a => parseFloat(a.quantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        array4.push(totalLoanReturn)
    }

    // Return Quantity
    let array5 = [];
    for (let i = 0; i < items?.length; i++) {
        const filtered = returnSales.filter(rs => rs.code === items[i].code);
        array5.push(filtered);
    }

    let array6 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array5[i]?.map(a => parseFloat(a.returnQuantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        array6.push(totalLoanReturn)
    }


    // Joining Balance To data

    let data = [];
    for (let i = 0; i < items?.length; i++) {
        const product = ({
            _id: items[i]._id, name: items[i]?.name,
            code: items[i].code, category: items[i].category,
            brand: items[i].brand, minimum: items[i].minimum,
            origin: items[i].origin, unit: items[i].unit, status: items[i].status,
            purchase: items[i].purchase, sell: items[i].sell, totalSale: array2[i],
            totalPurchase: array4[i], opening: items[i].opening, totalReturn: array6[i],
            stock: (items[i]?.opening) + array4[i] + array6[i] - array2[i], stockCost: (items[i].opening
                + array4[i] + array6[i] - array2[i]) * items[i].purchase,
        });

        data.push(product);
    }

    // const filtered = data?.filter((product) => product.stock>=1);

    // console.log(filtered);

    const sorted = sort === "All" ? data.sort(function (a, b) {
        return (a?.totalSale < b?.totalSale) ? 1 : ((a?.totalSale > b?.totalSale) ? -1 : 0);
    }) : data.filter(product => product.category === sort).sort(function (a, b) {
        return (a?.totalSale < b?.totalSale) ? 1 : ((a?.totalSale > b?.totalSale) ? -1 : 0);
    })

    // if (isLoading) {
    //     return <Loader></Loader>
    // }

    // const handleSearchResult = (event) => {
    //     // const searchText = event.target.value;
    //     // const match = data.filter(product => product.name.includes(searchText) || product.name.toLowerCase().includes(searchText)
    //     //     || product.name.toUpperCase().includes(searchText));
    //     // setSearchResult(match);
    //     setSearchTerm(event.target.value);
    //     setIsSearching(true);
    // }

    const totalBalance = isSearching ? searchResult.reduce((total, currentValue) => total + parseFloat(currentValue.stockCost), 0) : sorted.reduce((total, currentValue) => total + parseFloat(currentValue.stockCost), 0);

    return (
        <div className='mx-2'>
            <h1 className='text-center text-lg mt-8'>Current Product Stocks</h1>
            <div className='flex justify-between mt-16'>
                <div className='flex'>
                    <div className={`form-control w-40 max-w-xs mt-3 lg:mt-0 lg:mr-2`}>
                        <label class="label">
                            <span class="label-text">Select Category</span>
                        </label>
                        <select ref={sortRef} style={{ padding: '' }} onChange={handleCategoryChange} defaultValue='' class="rounded-lg text-black text-xs select select-bordered select-error w-full max-w-xs" required>
                            <option selected>All</option>
                            {categories?.map(category => <option>{category?.name}</option>)}
                        </select>
                    </div>
                    <div class="form-control w-28  max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text text-xs">Show</span>
                        </label>
                        <select onChange={handleLimit} defaultValue='' class="text-center rounded-lg text-black select select-bordered select-error w-full text-xs max-w-xs" required>
                            <option selected >20</option>
                            <option>10</option>
                            <option>50</option>
                            <option>100</option>
                            <option>All</option>
                        </select>
                    </div>
                </div>
                <div>
                    <div className='text-right'>
                        <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                            table="table-to-xls"
                            filename={`Stock Sheet`}
                            sheet={`stock-sheet`}
                            buttonText="Export to exel" />
                        {/* <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button> */}
                    </div>
                    <div className='flex justify-end items-center my-4'>
                        <div class="form-control w-52">
                            <input onChange={handleSearchResult} type="text" placeholder="Search Product" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="overflow-x-auto mt-4">
                <table id="table-to-xls" class="table w-1/2 mx-auto mb-0 text-xs">
                    <thead>
                        <tr className='text-center'>
                            <th className='bg-red-600 text-white normal-case'>SL</th>
                            <th className='bg-red-600 text-white normal-case'>Code</th>
                            <th className='bg-red-600 text-white normal-case'>Product Name</th>
                            <th className='bg-red-600 text-white normal-case'>Category</th>
                            <th className='bg-red-600 text-white normal-case'>Brand</th>
                            <th className='bg-red-600 text-white normal-case'>Unit</th>
                            <th className='bg-red-600 text-white normal-case'>Origin</th>
                            <th className='bg-red-600 text-white normal-case'>Cost Price</th>
                            <th className='bg-red-600 text-white normal-case'>Sales Price</th>
                            <th className='bg-red-600 text-white normal-case'>Opening Balance</th>
                            <th className='bg-red-600 text-white normal-case'>Total Sales</th>
                            <th className='bg-red-600 text-white normal-case'>Total Sales Return</th>
                            <th className='bg-red-600 text-white normal-case'>Total Purchase</th>
                            <th className='bg-red-600 text-white normal-case'>Current Stock</th>
                            <th className='bg-red-600 text-white normal-case'>Cost Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            // isSearching ? searchResult.map((product, index) => <tr className='text-center'>
                            //     <td>{index + 1}</td>
                            //     <td>{product?.code}</td>
                            //     <td>{product?.name}</td>
                            //     <td>{product?.purchase}</td>
                            //     <td>{product?.sell}</td>
                            //     <td className='text-blue-800'>{product?.opening}</td>
                            //     <td className='text-green-600'>{(product?.totalSale).toFixed(2)}</td>
                            //     <td className='text-yellow-700'>{(product?.totalPurchase).toFixed(2)}</td>
                            //     <td className='text-red-600 font-bold'>{(product?.stock).toFixed(2)}</td>
                            //     <td>{(product.stockCost).toFixed(2)}</td>
                            // </tr>) :

                            sorted.map((product, index) => <tr className='text-center'>
                                <td>{limit === "All" ? index + 1 : index + 1 + limit * (pageNo - 1)}</td>
                                <td>{product?.code}</td>
                                <td>{product?.name}</td>
                                <td>{product?.category}</td>
                                <td>{product?.brand}</td>
                                <td>{product?.origin}</td>
                                <td>{product?.unit}</td>
                                <td>{product?.purchase}</td>
                                <td>{product?.sell}</td>
                                <td className='text-blue-800'>{product?.opening}</td>
                                <td className='text-green-600'>{product?.totalSale}</td>
                                <td className='text-green-600'>{product?.totalReturn}</td>
                                <td className='text-yellow-700'>{product?.totalPurchase}</td>
                                <td className='text-red-600 font-bold'>{product?.stock}</td>
                                <td>{(product.stockCost).toFixed(2)}</td>
                            </tr>)
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'>Total</th>
                            <th className='bg-red-600 text-white normal-case'>{(totalBalance)?.toFixed(2)}</th>
                        </tr>
                    </tfoot>
                </table>
                {limit === "All" ? "" : <div className='mb-8 mt-2 text-xs'>
                    <ReactPaginate
                        forcePage={pageNo - 1}
                        previousLabel={'Pre'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={'btn-group justify-center'}
                        pageClassName={'btn btn-error text-white'}
                        activeClassName={'btn bg-white text-black'}
                        previousClassName={'btn btn-error text-white'}
                        nextClassName={'btn btn-error text-white'}
                        breakClassName={'btn btn-error text-white'}>

                    </ReactPaginate>
                </div>}
            </div>
        </div>
    );
};

export default CurrentStock;


{/* <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Stock Sheet`}
                    sheet={`stock-sheet`}
                    buttonText="Export to exel" />
                {/* <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button> */}
            // </div>
            // <div className='flex justify-center lg:justify-end items-center my-4'>
            //     <div class="form-control w-60">
            //         <input onChange={handleSearchResult} type="text" placeholder="Search Product" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
            //     </div>
            // </div> */}