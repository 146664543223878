import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editOrigin } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';



const OriginField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [origins, setOrigins] = useState([]);
    const [updateOrigin, setUpdateOrigin] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch('https://servers.expressmartbd.com/jibon-traders/getorigins')
            .then(res => res.json())
            .then(data => {
                setOrigins(data);
                setLoading(false)
            })
    }, [origins, setOrigins, updateOrigin, setUpdateOrigin, dispatch])

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0]
        fetch('https://servers.expressmartbd.com/jibon-traders/origins', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({user,...data}),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Origin Added')
                reset();
            })

    }

    const handleDeleteOrigin = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.expressmartbd.com/jibon-traders/getorigins/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateOrigin(null);
                })
        }
    };

    const handleupadateOrigin = (origin) => {

        // fetch(`https://servers.expressmartbd.com/jibon-traders/getorigins/${Origin._id}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         dispatch(editOrigin(data))
        //     })
        setUpdateOrigin(origin);
    }

    const handleApprove=(origin)=>{
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.expressmartbd.com/jibon-traders/getorigins/${origin._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Origin Approved');
                // window.location.reload(false)
            })
    }

    return (
        <div className='shadow-2xl px-2 pb-5 my-5 text-xs'>
            <form onSubmit={handleSubmit(onSubmit)} className='container flex flex-col lg:flex-row md:flex-row gap-2 justify-center items-center'>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Origin Name</span>
                    </label>
                    <input {...register("name")} type="text" placeholder="Type Origin Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Status</span>
                    </label>
                    <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                        <option selected>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>
                <input disabled={!userAc?.addOrigin} type="submit" value='Add Origin' className='btn bg-red-600 text-white lg:mt-9' />
            </form>

            {
                origins.length ? <div class="overflow-x-auto mt-8">
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>Origin Name</th>
                                <th className='bg-red-600 text-white normal-case'>Status</th>
                                <th className='bg-red-600 text-white normal-case'>Added By</th>
                                <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                <th className='bg-red-600 text-white normal-case'>Edit</th>
                                <th className='bg-red-600 text-white normal-case'>Delete</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                origins.map((origin, index) => <tr className='hover'>
                                    <td>{index + 1}</td>
                                    <td>{origin.name}</td>
                                    <td> <button className={`btn btn-xs normal-case ${origin.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{origin?.status}</button> </td>
                                    <td><button className='btn btn-xs btn-primary text-white normal-case'>{origin?.user ? origin.user : "User"}</button></td>
                                    {userAc.approvalOrigin? <td>{origin?.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{origin?.approvedBy}</button>: <button onClick={()=>handleApprove(origin)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td>:
                                   <td>{origin.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{origin.approvedBy}</button>:<button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                    {userAc?.editOrigin && <td><button><label for="update-modal" onClick={() => handleupadateOrigin(origin)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                    {userAc?.deleteOrigin && <td><button onClick={() => handleDeleteOrigin(origin._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div> : ""
            }
            {
                updateOrigin && <UpdateModal setUpdateOrigin={setUpdateOrigin} updateOrigin={updateOrigin}></UpdateModal>
            }
        </div>
    );
};

export default OriginField;