import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { toast } from 'react-toastify';
import Loader from '../../../Shared/Loader';
import logo from '../../../../assets/images/logo.jpg';
import { useReactToPrint } from 'react-to-print';
import BusinessInfo from '../../../Shared/BusinessInfo';

const StockLedger = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [sales, setSales] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState();
    const [stockLedger, setStockLedger] = useState([]);
    const [opening, setOpening] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false)
    const [details, setDetails] = useState('');
    const [isFilter, setIsFilter] = useState(false);
    const componentRef = useRef();

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    console.log(stockLedger);

    useEffect(() => {
        fetch(`https://servers.expressmartbd.com/jibon-traders/getproducts`)
            .then(res => res.json())
            .then(data => {
                setProducts(data);
                // setLoading(false)
            })
    }, [])

    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        const code = e.target.code.value;

        const options = {
            timeZone: 'Asia/Dhaka',
            hour12: false,
        };
        const currentTime = new Date().toLocaleString('en-US', options);

        const url = `https://servers.expressmartbd.com/jibon-traders/getstockledger?startDate=${startDate}&endDate=${endDate}&code=${code}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setStockLedger(data.stocks);
                setOpening(data.openingBalance);
                setIsFilter(true);
                setIsLoaded(true);
                setDetails({ startDate: startDate, name: selectedProduct?.name, code: code, range: startDate + " to " + endDate, time: currentTime });
                // setLoading(false)
            })

    }

    const totalPurchase = stockLedger.reduce((total, currentValue) => {
        if (currentValue.purchaseDate) {
            return total + parseInt(currentValue.quantity);
        } else {
            return total;
        }
    }, 0);

    const totalSales = stockLedger.reduce((total, currentValue) => {
        if (currentValue.salesDate) {
            return total + parseInt(currentValue.quantity);
        } else {
            return total;
        }
    }, 0);

    // if (loading) {
    //     return <Loader />
    // }
    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Bank Statement Of ${details?.accountName}`,

    })


    const handleSearchResult = (event) => {
        setSearchText(event.target.value)
        const match = products.filter(product => product.name.includes(searchText) ||
            product.name.toLowerCase().includes(searchText) || product.name.toUpperCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const handleAddProductToInvoice = (product) => {
        setSelectedProduct(product);
        setIsSearching(false)
        setSearchText('');
    }


    return (
        <div className='mx-2'>
            <h2 className='mt-8 text-center text-lg font-bold'>Stock Ledger Report</h2>
            <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center'>
                <div className='lg:mt-28 mt-8 mr-2'>
                    <div className='flex flex-col lg:flex-row justify-between lg:items-center'>
                        <div class="form-control w-80 lg:w-40 mx-auto">
                            <input onChange={handleSearchResult} value={searchText} type="text" placeholder="Search Product" class="input text-xs input-bordered border-dark-purple w-80 mx-auto lg:w-60 max-w-xs" />
                        </div>
                    </div>
                    <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 mx-auto gap-2 bg-gray-200 p-2 ${isSearching ? 'h-60' : "h-8"} w-80 overflow-auto`}>
                        {
                            isSearching ? searchResult.map(product =>
                                <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer normal-case`}>{product?.name}</label>) :
                                products.map(product =>
                                    <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer normal-case`}>{product?.name}</label>)
                        }
                    </div>
                </div>
                <div className='flex justify-center items-center lg:mr-20'>
                    <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-center'>
                        <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">Starting Date</span>
                            </label>
                            <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">End Date</span>
                            </label>
                            <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 lg:w-40 max-w-xs">
                            <label class="label">
                                <span class="label-text">Product Code</span>
                            </label>
                            <div class="form-control w-80 lg:w-40">
                                <input name='code' type="text" defaultValue={selectedProduct?.code} placeholder="Product Code" class="input text-xs input-bordered border-dark-purple w-full lg:w-40 max-w-xs" />
                            </div>
                        </div>
                        <input type='submit' value='Search' className='btn btn-md btn-error w-80 mx-auto lg:w-20 text-white mt-4 lg:mt-9 lg:ml-2' />
                    </form>
                </div>
            </div>
            {isLoaded && <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Bank Statement- ${details.accountName}`}
                    sheet={`bankstatement-${details.accountName}`}
                    buttonText="Export to exel" />
                <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
            </div>
            }
            {isLoaded && <div ref={componentRef}>
                <div className='flex justify-center items-center mt-8'>
                    <div>
                        <img src={BusinessInfo.img} className="w-20 lg:w-44 lg:mr-20 lg:mb-4" alt="" />
                    </div>
                    <div className='lg:mr-40'>
                        <h1 className='text-xl lg:text-4xl text-black text-center'>{BusinessInfo.fullName}</h1>
                        <h1 className='text-sm lg:text-xl text-black text-center'>{BusinessInfo.dealer}</h1>
                        <h1 className='text-xs text-black text-center'>{BusinessInfo.address}</h1>
                        <h1 className='text-xs lg:text-xl text-black text-center'>{BusinessInfo.mobile}</h1>
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full my-px" />
                    <hr class="border-b border-gray-300 w-full my-px" />
                </div>

                <div className='text-left ml-20 mt-8 text-xs'>
                    <h4>Report Type: <span className='font-bold'>Stock Ledger Statement</span></h4>
                    <h4>Product Name: {details?.name}</h4>
                    <h4>Product Code: {details?.code}</h4>
                    <h4>Report Date Range: {details?.range}</h4>
                    <h4>Report Time: {details?.time}</h4>
                </div>

                <div className='mb-20'>
                    <div class="overflow-x-auto mt-8  text-xs">
                        <table id='table-to-xls' class="table w-full text-center shadow-xl rounded-lg">
                            <thead>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>SL</th>
                                    <th className='bg-red-600 text-white normal-case'>Date</th>
                                    <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                    <th className='bg-red-600 text-white normal-case'>Purchase Quantity</th>
                                    <th className='bg-red-600 text-white normal-case'>Sell Quantity</th>
                                    <th className='bg-red-600 text-white normal-case'>Details</th>
                                </tr>

                            </thead>
                            <tbody>
                                {
                                    stockLedger.map((stock, index) => <tr>
                                        <td className='border border-red-600'>{index + 1}</td>
                                        <td className='border border-red-600'>{stock?.salesReturnDate ? stock?.salesReturnDate : stock?.date}</td>
                                        <td className='border border-red-600'>{stock?.invoice}</td>
                                        <td className='border border-red-600'>{stock?.purchaseDate ? stock?.quantity :
                                            stock?.salesReturnDate ? stock?.quantity : "-"}</td>
                                        <td className='border border-red-600'>{stock?.salesDate ? stock?.quantity : "-"}</td>
                                        <td className='border border-red-600'>{stock?.purchaseDate ? "Purchase" : stock?.salesReturnDate ? "Sales Return" : "Sales"}</td>
                                    </tr>)
                                }

                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'>Opening Balance On {details?.startDate}</th>
                                    <th className='bg-red-600 text-white text-sm'>{opening}</th>
                                    <th className='bg-red-600 text-white text-sm'>{totalPurchase}</th>
                                    <th className='bg-red-600 text-white text-sm'>{totalSales}</th>
                                    <th className='bg-red-600 text-white text-sm normal-case'>Closing: {opening + totalPurchase - totalSales}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>

            </div>}
        </div>
    );
};

export default StockLedger;