import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Shared/Loader';
import { useRef } from 'react';
import AddPurchaseProductList from './AddPurchaseProductList';
import AddPurchaseInvoice from './AddPurchaseInvoice';
import { selectSupplier } from '../../../../features/PurchaseSlice/PurchaseSlice';
import { format } from 'date-fns';
import useFetchData from '../../../../hooks/useFetchData';
import SupplierList from '../../../../hooks/SupplierList';



const AddPurchase = ({ }) => {
    const { posSupplier, isSupplier } = useSelector((state) => state.purchaseInvoice);
    const supply = useSelector((state) => state.purchaseInvoice.supplier);
    const { items } = useFetchData('https://servers.expressmartbd.com/jibon-traders/getpurchaseinvoice');
    const [isSearching, setIsSearching] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const [loading, setLoading] = useState(true);
    const [suppliers, setSuppliers] = useState([]);
    const [details, setDetails] = useState();
    const [currentSupplier, setCurrentSupplier] = useState([]);
    const [isInvoice, setIsInvoice] = useState(false);
    // const [isSupplier, setIsSupplier] = useState(false);
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    console.log(details);

    const supplierCodeRef = useRef('');
    const supplierRef = useRef('');
    const addressRef = useRef('');
    const phoneRef = useRef('');
    const openingRef = useRef('');
    const dateRef = useRef('');

    const dispatch = useDispatch();



    useEffect(() => {
        fetch('https://servers.expressmartbd.com/jibon-traders/getsuppliers')
            .then(res => res.json())
            .then(data => {
                setSuppliers(data);
                setLoading(false)
            })
    }, [])


    if (loading) {
        return <Loader />
    }

    const handleSearchResult = (event) => {
        setSearchText(event.target.value)
        const match = suppliers.filter(supplier => supplier.name.includes(searchText)
            || supplier.name.toLowerCase().includes(searchText) ||
            supplier.name.toUpperCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    // const handleSelectSupplier = (suppliar) => {
    //     dispatch(selectSupplier(suppliar))
    //     setIsSearching(false)
    //     setSearchText('');

    // }

    // const handleSelectSupplier = (supplier) => {
    //     const opening = parseFloat(supplier?.opening)
    //     const url = `https://servers.expressmartbd.com/jibon-traders/filteredsupplier?code=${supplier?.code}`
    //     fetch(url)
    //         .then(res => res.json())
    //         .then(data => {
    //             console.log(data);
    //             const due = opening + data?.defaultTotalDue - data?.defaultTotalPayment;
    //             setCurrentSupplier({ ...supplier, due });
    //         })
    //     setIsSearching(false)
    //     setSearchText('');
    //     setIsSupplier(true);
    // }


    const handlePurchaseDetails = (event) => {
        const purchaseInvoice = event.target.value;
        const supplierCode = supplierCodeRef.current.innerText;
        const purchaseSupplier = supplierRef.current.innerText;
        const purchaseAddress = addressRef.current.innerText;
        const purchasePhone = phoneRef.current.innerText;
        const purchaseOpening = openingRef.current.innerText;
        const purchaseDate = dateRef.current.value;
        const isExist = items?.filter(memo => memo?.purchaseInvoice === purchaseInvoice);
        const duplicate = isExist?.length ? true : false;
        setIsInvoice(true);

        setDetails({ purchaseInvoice, supplierCode, purchaseSupplier, purchaseAddress, purchasePhone, purchaseOpening, purchaseDate, duplicate })

    }


    return (
        <div className='w-full px-5 pb-5 my-5 text-xs'>
            <h1 className='text-center bg-red-600 p-3 text-white text-lg rounded-xl'>Add A Purchase</h1>
            <div className='grid grid-cols-1 lg:grid-cols-12 gap-4'>
                <div class="form-control w-full lg:w-60 max-w-xs mt-5 col-span-2">
                    <div class="form-control lg:w-60 mx-auto">
                        <SupplierList suppliers={suppliers} />
                        {/* <input onChange={handleSearchResult} value={searchText} type="text" placeholder="Search Supplier" class="rounded-lg bg-gray-300 p-3  text-center  focus:border-blue-500 focus:outline-none w-full max-w-xs" />
                    </div>
                    <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-16' : "h-8"} w-80 overflow-auto`}>
                        {
                            isSearching ? searchResult.map(supplier =>
                                <label for="update-modal" onClick={() => handleSelectSupplier(supplier)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{supplier?.name}</label>) :
                                suppliers.map(supplier =>
                                    <label for="update-modal" onClick={() => handleSelectSupplier(supplier)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{supplier?.name}</label>)
                        } */}
                    </div>
                </div>
                <div className='lg:col-span-6 mt-4 overflow-auto lg:lg:overflow-visible'>
                    <table class="table lg:w-1/2 lg:ml-32">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Supplier Code</th>
                                <th className='bg-red-600 text-white normal-case'>Supplier Name</th>
                                <th className='bg-red-600 text-white normal-case'>Supplier Address</th>
                                <th className='bg-red-600 text-white normal-case'>Mobile No</th>
                                <th className='bg-red-600 text-white normal-case'>Previews Due</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            <tr className=''>
                                <td ref={supplierCodeRef} className='h-8'>{posSupplier?.code}</td>
                                <td ref={supplierRef} className='h-8'>{posSupplier?.name}</td>
                                <td ref={addressRef}>{posSupplier?.address}</td>
                                <td ref={phoneRef}>{posSupplier?.phone}</td>
                                <td ref={openingRef}>{(posSupplier?.due)?.toFixed(2)}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <br />
                <div className='lg:col-span-4 mt-4'>
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Select Date</th>
                                <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            <tr className=''>
                                <td><input ref={dateRef} defaultValue={today} type="date" className='rounded-lg bg-gray-200 p-3  text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                                <td><input disabled={!isSupplier} onChange={handlePurchaseDetails} type="text" defaultValue={"Inv-0001"} className='rounded-lg bg-gray-200 p-3 w-40 text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                            </tr>
                            {details?.duplicate && <tr className=''>
                                <td></td>
                                <td className='text-red-600 font-bold'>(Duplicate Please Change)</td>
                            </tr>}

                        </tbody>
                    </table>
                </div>
            </div>
            <div className='grid grid-cols-1 relative'>
                <AddPurchaseProductList />
                <AddPurchaseInvoice details={details} isInvoice={isInvoice} />
            </div>
        </div>
    );
};

export default AddPurchase;