import { RouterProvider } from "react-router-dom";
import routes from "./routes/routes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "./Firebase/Firebase";
import { useDispatch } from "react-redux";
import Loader from "./pages/Shared/Loader";
import { getUser } from "./features/userSlice/userSlice";
// import { getAuth } from "firebase/auth";


function App() {
  const [currentUser, setCurrentUser] = useState([])
  const dispatch = useDispatch();
  const [user, loading, error] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(true);

  const [isPopupActive, setPopupActive] = useState(true);
  const [isWritten, setIsWritten] = useState(false);

  const textRef = useRef();

  console.log(textRef?.current?.value);

  const togglePopup = () => {
    setPopupActive(!isPopupActive);
    toast.success('দয়া করে কিছুক্ষণ অপেক্ষা করুন')
  };

  const onChangeValue = (e) => {
    const isValue = e.target.value
    if (isValue) {
      setIsWritten(true)
    }

  }




  useEffect(() => {
    fetch(`https://servers.expressmartbd.com/jibon-traders/getusers`)
      .then(res => res.json())
      .then(data => {
        setCurrentUser(data);
        setIsLoading(false)
      })
  }, [])

  if (isLoading) {
    return <Loader />
  }
  if (loading) {
    return <Loader />
  }

  const filter = currentUser?.filter(u => u?.email === user?.email)
  dispatch(getUser(filter[0]));





  // // Attach the event listener after the component is mounted
  // window.addEventListener('resize', handleResize);

  // // Clean up the event listener when the component unmounts
  // return () => {
  //   window.removeEventListener('resize', handleResize);
  // };

  // useEffect(() => {
  //   handleResize()
  // }, []);






  return (
    <div>
      {isPopupActive && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={(e) => e.stopPropagation()} // Prevent clicks outside the popup
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-80 z-50"
            onClick={(e) => e.stopPropagation()} // Prevent propagation inside popup
          >
            <h2 className="text-lg font-semibold mb-2 text-red-600">সতর্কতা</h2>
            <p className="mb-4">সন্মানিত Jibon Traders, দয়া করে আপনার বকেয়া সার্ভার বিল পরিশোধ করুন। আপনার বকেয়া ১৫০০/- টাকা। 01772545974 (Bkash, Rocket, Nagad). পরিশোধ করে শেষের নম্বরটি প্রদান করুন।</p>
            <input ref={textRef} onChange={(e) => onChangeValue(e)} className="input input-bordered" type="text" />
            <button
              disabled={!isWritten}
              onClick={togglePopup}
              className="px-4 py-2 mt-2 bg-red-500 text-white rounded shadow hover:bg-red-600"
            >
              পেমেন্ট দেওয়া হয়েছে
            </button>
          </div>
        </div>
      )}
      <RouterProvider router={routes} />
      <ToastContainer />
    </div>
  );
}

export default App;



// {
//   "name":"Jahid Hossen",
//   "userManagement":true,
//   "editUser":true,
//   "deleteUser":true,
//   "productsInfo":true,
//   "addBrand":true,
//   "editBrand":false,
//   "deleteBrand":false,
//   "addCategory":true,
//   "editCategory":false,
//   "deleteCategory":false
//   "addSupplier":true,
//   "editSupplier":false,
//   "deleteSupplier":false,
//   "addUnit":true,
//   "editUnit":false,
//   "deleteUnit":false,
//   "addOrigin":true,
//   "editOrigin":false,
//   "deleteOrigin":false,
//   "addProduct":true,
//   "editProduct":false,
//   "deleteProduct":false,
//   "purchase":true,
//   "addPurchase":true,
//   "managePurchase":true,
//   "editPurchase":false,
//   "deletePurchase":false,
//   "sales":true,
//   "addCustomer":true,
//   "editCustomer":false,
//   "deleteCustomer":false,
//   "addSales":true,
//   "manageSales":true,
//   "editSales":false,
//   "deleteSales":false,
//   "accountsInfo":true,
//   "addExpenseHead":true,
//   "editExpenseHead":false,
//   "deleteExpenseHead":false,
//   "addIncomeHead":true,
//   "editIncomeHead":false,
//   "deleteIncomeHead":false,
//   "addBankAccount":true,
//   "editBankAccount":false,
//   "deleteBankAccount":false,
//   "addLoanAccount":true,
//   "editLoanAccount":false,
//   "deleteLoanAccount":false,
//   "addOpeningCapital":true,
//   "editOpeningCapital":false,
//   "deleteOpening":false,
//   "transactions":true,
//   "addIncome":true,
//   "editIncome":false,
//   "deleteIncome":false,
//   "addExpense":true,
//   "editExpense":false,
//   "deleteExpense":false,
//   "addCollection":true,
//   "editCollection":false,
//   "deleteCollection":false,
//   "addPayment":true,
//   "editPayment":false,
//   "deletePayment":false,
//   "addBankDeposit":true,
//   "editBankDeposit":false,
//   "deleteBankDeposit":false,
//   "addBankWithdraw":true,
//   "editBankWithdraw":false,
//   "deleteBankWithdraw":false,
//   "addLoanTaken":true,
//   "editLoanTaken":false,
//   "deleteLoanTaken":false,
//   "addLoanReturn":true,
//   "editLoanReturn":false,
//   "deleteLoanReturn":false,
//   "reports":true,
//   "cashStatement":true,
//   "bankStatement":true,
//   "balanceSheet":true,
//   "profitSheet":true,
//   "currentStock":true,
//   "stockLedger":true,
//   "customerDueReport":true,
//   "customerLedgerReport":true,
//   "supplierDueReport":true,
//   "supplierLedgerReport":true,
//   "loanDueReport":true,
//   "invoiceWiseProfit":true
// }