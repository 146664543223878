import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import sale from '../../../../src/assets/images/sale.png'
import useFetchData from '../../../hooks/useFetchData';

const TodaySales = () => {

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    const { items: sales } = useFetchData("https://servers.expressmartbd.com/jibon-traders/getcurrentmonthsales");

    const totalSales= sales?.filter(sale=>sale?.day===today);

   
//style={{width:'300px',height:'120px'}}
    return (
        <div class="mx-auto w-11/12 bg-coral text-black rounded-lg overflow-hidden shadow-lg glassmorphism">
    <div className='grid grid-cols-12'>
        <div class="col-span-5 w-full">
            <img src={sale} alt="Card image" class="mx-auto my-auto h-28 w-28 md:h-full md:w-full rounded-full border-4 border-red-600 object-cover"/>
        </div>
        <div class="col-span-6 my-auto">
            <div class="text-lg font-semibold mb-2">Today Sale</div>
            <p class="text-xl text-black">{totalSales?.length?(totalSales[0]?.sales).toFixed(2):0.00} TK</p>
        </div>
    </div>
</div>


    );
};

export default TodaySales;